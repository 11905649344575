ion-alert {
  --width: 320px !important;
  --max-width: 320px !important;

  .alert-wrapper {
    border-radius: 24px !important;

    .alert-btn-ok {
      // background-color: var(--ion-color-primary);
      // color: white;
      border-radius: 9999px;
      padding-inline: 24px;
      margin-right: 16px;
    }

    .alert-btn-cancel {
      // color: var(--ion-color-primary);
      border: 1px solid var(--ion-color-primary);
      border-radius: 9999px;
      padding-inline: 24px;
    }

    .alert-message {
      max-height: fit-content;
    }

    .alert-button-group {
      padding-bottom: 16px;
    }
  }

  &.confirm-keyword-alert {
    .alert-wrapper {
      .alert-message {
        padding-bottom: 0;
      }
    }
  }

  &.alert-danger-theme {
    .alert-title,
    .alert-msg-text {
      color: var(--ion-color-danger);
    }

    .alert-btn-ok {
      background-color: var(--ion-color-danger);
      color: white;
      border-radius: 9999px;
      padding-inline: 24px;
    }

    .alert-btn-cancel {
      color: var(--ion-color-danger);
      border: 1px solid var(--ion-color-danger);
      border-radius: 9999px;
      padding-inline: 24px;
    }
  }

  &.alert-dashboard-theme {
    .alert-btn-ok {
      background-color: var(--ion-color-dashboard-primary);
      color: white;
    }

    .alert-btn-cancel {
      color: var(--ion-color-dashboard-primary);
      border-color: var(--ion-color-dashboard-primary);
    }
  }

  &.alert-residents-theme {
    .alert-btn-ok {
      background-color: var(--ion-color-residents-primary);
      color: white;
    }

    .alert-btn-cancel {
      color: var(--ion-color-residents-primary);
      border-color: var(--ion-color-residents-primary);
    }
  }

  &.alert-staff-theme {
    .alert-btn-ok {
      background-color: var(--ion-color-staff-primary);
      color: black;
    }

    .alert-btn-cancel {
      color: var(--ion-color-staff-primary);
      border-color: var(--ion-color-staff-primary);
    }
  }

  &.alert-communication-theme {
    .alert-btn-ok {
      background-color: var(--ion-color-communication-primary);
      color: white;
    }

    .alert-btn-cancel {
      color: var(--ion-color-communication-primary);
      border-color: var(--ion-color-communication-primary);
    }
  }

  &.alert-documents-theme {
    .alert-btn-ok {
      background-color: var(--ion-color-documents-primary);
      color: white;
    }

    .alert-btn-cancel {
      color: var(--ion-color-documents-primary);
      border-color: var(--ion-color-documents-primary);
    }
  }

  &.alert-organization-theme {
    .alert-btn-ok {
      background-color: var(--ion-color-organization-primary);
      color: white;
    }

    .alert-btn-cancel {
      color: var(--ion-color-organization-primary);
      border-color: var(--ion-color-organization-primary);
    }
  }
}

.wrap-label .alert-checkbox-label {
  white-space: normal;
}
