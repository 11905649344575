@use 'mixins';

.mat-input-condensed {
  .mat-mdc-form-field-infix {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    min-height: 48px !important;
  }
}

// Making the select panel height configurable
div.mat-mdc-select-panel {
  &.panel-50 {
    max-height: 50vh !important;
  }
  &.panel-60 {
    max-height: 60vh !important;
  }
  &.panel-70 {
    max-height: 70vh !important;
  }
  &.panel-80 {
    max-height: 80vh !important;
  }
}

@include mixins.breakpoint-up('md') {
  .mat-accordion .mat-expansion-panel:first-of-type {
    border-top-left-radius: var(--alc-card-radius) !important;
    border-top-right-radius: var(--alc-card-radius) !important;
  }

  .mat-accordion .mat-expansion-panel:last-of-type {
    border-bottom-left-radius: var(--alc-card-radius) !important;
    border-bottom-right-radius: var(--alc-card-radius) !important;
  }
}

mat-accordion.lock-radius {
  &.mat-accordion .mat-expansion-panel:first-of-type {
    border-top-left-radius: var(--alc-card-radius) !important;
    border-top-right-radius: var(--alc-card-radius) !important;
  }

  &.mat-accordion .mat-expansion-panel:last-of-type {
    border-bottom-left-radius: var(--alc-card-radius) !important;
    border-bottom-right-radius: var(--alc-card-radius) !important;
  }
}
