@use '@ionic/angular/css/core.css';
@use '@ionic/angular/css/normalize.css';
@use '@ionic/angular/css/structure.css';
@use '@ionic/angular/css/typography.css';

@use '@ionic/angular/css/padding.css';
@use '@ionic/angular/css/float-elements.css';
@use '@ionic/angular/css/text-alignment.css';
@use '@ionic/angular/css/text-transformation.css';
@use '@ionic/angular/css/flex-utils.css';
@use '@ionic/angular/css/display.css';

@use './theme/variables';
@use './styles';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@tailwind base;
@tailwind components;
@tailwind utilities;

.blur-effect {
  filter: blur(5px);
}

// HACK: When using tailwindcss you should be able to apply a border to 1 side of an element
// by using something like border-b border-b-medium. However, if you have tailwind configured
// with preflight set to false (as we do) then the default border width is set to 2px and the
// the border style is set to none. This means 1) you need to add border-solid manually every
// you want a border applied, and 2) you have to add border-0 to reset the border width to 0
// otherwise a 2px border gets applied to all sides. This is not intuitive as the documentation
// shows examples of applying a border to 1 side without the need for border-0 and border-solid.
// https://github.com/tailwindlabs/tailwindcss/issues/289#issuecomment-860280393
* {
  border-width: 0;
  border-style: solid;
}

app-component {
  .toolbar {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.content-surface {
  --background: var(--ion-color-white);
}

// color classes for the main menu buttons
.alc-fg-color.alc-dashboard-primary {
  color: var(--ion-color-dashboard-primary);
}
.alc-fg-color.alc-residents-primary {
  color: var(--ion-color-residents-primary);
}
.alc-fg-color.alc-staff-primary {
  color: var(--ion-color-staff-primary);
}
.alc-fg-color.alc-organization-primary {
  color: var(--ion-color-organization-primary);
}
.alc-fg-color.alc-messages-primary {
  color: var(--ion-color-messages-primary);
}

// Hack to get the card height to be correct

ion-slides.card-slides {
  height: initial;
  .swiper-container,
  .swiper-slide,
  .swiper-wrapper,
  .swiper-slide,
  .swiper-pagination-progress .swiper-pagination-progressbar,
  .swiper-container-vertical > .swiper-pagination-progress,
  .swiper-container-3d .swiper-slide-shadow-left,
  .swiper-container-3d .swiper-slide-shadow-right,
  .swiper-container-3d .swiper-slide-shadow-top,
  .swiper-container-3d .swiper-slide-shadow-bottom,
  .swiper-container-cube .swiper-slide,
  .swiper-container-cube .swiper-cube-shadow,
  .swiper-zoom-container,
  .swiper-scrollbar-drag,
  .swiper-lazy-preloader:after {
    height: initial;
  }
}

// status indicator dot that appears in the resident list
.indicator {
  height: 10px;
  width: 10px;
  border-radius: 50%;

  &.active {
    background-color: var(--ion-color-success);
  }
  &.inactive {
    background-color: var(--ion-color-indicator-inactive);
  }
}

.form-error-message {
  font-size: 9pt;
  color: red;
  padding-left: 16px;
}

.toast-generic {
  transform: translateY(-60px) !important;
}

.toast-success {
  transform: translateY(-60px) !important;
}

.toast-error {
  transform: translateY(-60px) !important;
}

// margin bottom for logs
.log-list {
  margin-bottom: 72px;
}

// Set the bottom border of items to span the full width of the item
[ion-item] {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.item-md.item-block,
.item-ios.item-block,
.item-wp.item-block {
  .item-inner {
    border-bottom-width: 0;
  }
}

.item-avatar {
  width: 40px;
  height: 40px;
  margin: 8px 0px;
  border-radius: 50%;
}

.avatar-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ion-color-residents-accent);
  border-radius: 50%;
  margin-right: 24px;
  height: 40px;
  width: 40px;
  &[item-start] {
    margin-right: 16px;
  }
}

// remove the thin gray border at the bottom of non active segment buttons
.segment-md .segment-button,
.segment-ios .segment-button,
.segment-wp .segment-button {
  border-bottom-color: rgba(0, 0, 0, 0);
}

.ion-p-end-0 {
  --padding-end: 0;
  --inner-padding-end: 0;
}

.ion-p-start-0 {
  --padding-start: 0;
}

.ion-p-inline-0 {
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;
}

// FORM STYLES

.form-input-stretch {
  width: 100%;
  .mat-mdc-form-field,
  .mat-mdc-form-field-infix {
    width: 100%;
  }
}

// HACK: A vertical line appears in the middle of the control on focus.
// It is unclear what is causing this. The controls render properly in the
// examples on their site, which would suggest something in our code is
// causing this behavior. Until we find out, this is a workaround to hide it.
.mdc-notched-outline__notch {
  border-right: none;
}

// The default line-height for mat-form-field is too large and looks weird
// This reduces it to a reasonable size
.mat-mdc-form-field-hint {
  @apply leading-3;
}

.text-selectable {
  user-select: text;
}

.filter-btn-container {
  margin: 8px 16px 16px;
}

ion-fab-button[fab-label]::after {
  position: absolute;
  content: attr(fab-label);
  z-index: 1;
  right: 55px;
  bottom: 4px;
  background-color: var(--ion-color-dark);
  padding: 10px 24px;
  border-radius: 25px;
  color: white;
  box-shadow:
    0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

// For some material components the default mat-elevation-z0 does not override the default elevation
// This is the same style as the default mat-elevation-z0 but with !important
.mat-elevation-z0 {
  box-shadow:
    0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14),
    0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}

// This is here to make PDF print work
// TODO: Figure out WHY this is needed, and if there's a better fix
@media print {
  body {
    position: relative;
  }
}
