ion-button[fill='outline'] {
  --border-width: 1px;
}

ion-button.text-button {
  --padding-start: 16px;
  --padding-end: 16px;
}

ion-button.no-padding {
  --padding-start: 0;
  --padding-end: 0;
}

.form-action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 16px;
  padding-left: 16px;
  ion-button {
    margin-left: 8px;
  }
}
