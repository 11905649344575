// Menu Items

.menu-item-2-line {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 56px !important;
  p {
    margin: 0;
    line-height: 16px;
  }
}

.c-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline: 16px;
  min-height: 48px;
  &__slot-start {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 content;
  }
  &__label {
    display: flex;
    flex-direction: column;
    flex: 1 1 content;
    margin: 0;
    font-size: 16px;
    color: var(--ion-color-dark);
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }
    &:not(:first-child) {
      font-size: 14px;
      color: var(--ion-color-medium);
    }
  }
  &__slot-end {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 content;
  }
}

.c-item--no-padding {
  padding-inline: 0;
}
