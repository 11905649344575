@mixin tabColor($color) {
  .tab-selected {
    color: var(--ion-color-#{$color}) !important;
    --color-selected: var(--ion-color-#{$color});
  }

  ion-tab-button {
    --color-selected: var(--ion-color-medium);
  }
}

// ANGULAR MATERIAL OVERRIDES
@mixin matSelectMultiline() {
  ::ng-deep .mat-mdc-select-panel mat-option.mat-mdc-option {
    height: unset;
    line-height: normal;
    @apply min-h-[48px] py-2;
  }
}

$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

@mixin breakpoint-up($bp) {
  @media (min-width: map-get($breakpoints, $bp)) {
    @content;
  }
}

@mixin breakpoint-down($bp) {
  @media (max-width: map-get($breakpoints, $bp)) {
    @content;
  }
}
