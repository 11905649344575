ion-card {
  @apply rounded-2xl m-0;
  --background: var(--ion-color-surface);
}

.c-card__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
