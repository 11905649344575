@use '@angular/material' as mat;

//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
html {
  @include mat.elevation-classes();
  @include mat.app-background();
}

// Custom Residents Primary Color
$alc-hot-pink: (
  50: #ffe4ed,
  100: #ffbcd3,
  200: #ff90b5,
  300: #ff6497,
  400: #ff4281,
  500: #ff216b,
  600: #ff1d63,
  700: #ff1858,
  800: #ff144e,
  900: #ff0b3c,
  A100: #ffffff,
  A200: #fff6f7,
  A400: #ffc3cc,
  A700: #ffa9b7,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

// Custom Staff Accent

$alc-light-purple: (
  50: #f7efff,
  100: #ebd7ff,
  200: #debcff,
  300: #d0a1ff,
  400: #c68cff,
  500: #bc78ff,
  600: #b670ff,
  700: #ad65ff,
  800: #a55bff,
  900: #9748ff,
  A100: #ffffff,
  A200: #ffffff,
  A400: #f5efff,
  A700: #e7d5ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

//
// 🎨 DEFAULT THEME
//
// Theme Color Pallettes
$alcomy-default-primary: mat.m2-define-palette(mat.$m2-light-blue-palette);
$alcomy-default-accent: mat.m2-define-palette(
  mat.$m2-pink-palette,
  A200,
  A100,
  A400
);
$alcomy-default-warn: mat.m2-define-palette(mat.$m2-red-palette); // optional

$alcomy-default-typography: mat.m2-define-typography-config();

$alcomy-default-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $alcomy-default-primary,
      accent: $alcomy-default-accent,
      warn: $alcomy-default-warn
    ),
    typography: $alcomy-default-typography
  )
);

html {
  @include mat.all-component-themes($alcomy-default-theme);
}

/* 
NOTE: Creating multiple light themes using mat-light-theme and angular-material-color 
will throw the following error:

WARNING: The same color styles are generated multiple times. Read more 
about how style duplication can be avoided in a dedicated guide. 
https://github.com/angular/components/blob/master/guides/duplicate-theming-styles.md

Since our application has multiple themes the following setting is set to true to disable
this warning.
*/

mat.$theme-ignore-duplication-warnings: true;

//
// 🎨 DASHBOARD THEME
//
.dashboard-theme {
  // Theme Pallettes
  $alcomy-dashboard-primary: mat.m2-define-palette(mat.$m2-light-blue-palette);
  $alcomy-dashboard-accent: mat.m2-define-palette(
    mat.$m2-pink-palette,
    A200,
    A100,
    A400
  );
  $alcomy-dashboard-warn: mat.m2-define-palette(
    mat.$m2-red-palette
  ); // optional
  // Theme Definition
  $alcomy-dashboard-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: $alcomy-dashboard-primary,
        accent: $alcomy-dashboard-accent,
        warn: $alcomy-dashboard-warn
      )
    )
  );

  @include mat.all-component-colors($alcomy-dashboard-theme);
}
//
// 🎨 RESIDENTS THEME V2
//
.residents-theme {
  // Theme Pallettes
  $alcomy-residents-primary: mat.m2-define-palette($alc-hot-pink);
  $alcomy-residents-accent: mat.m2-define-palette(mat.$m2-cyan-palette, A400);
  $alcomy-residents-warn: mat.m2-define-palette(
    mat.$m2-red-palette
  ); // optional
  // Theme Definition
  $alcomy-residents-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: $alcomy-residents-primary,
        accent: $alcomy-residents-accent,
        warn: $alcomy-residents-warn
      )
    )
  );

  @include mat.all-component-colors($alcomy-residents-theme);
}
//
// 🎨 STAFF THEME V2
//
.staff-theme {
  // Theme Pallettes
  $alcomy-staff-primary: mat.m2-define-palette(mat.$m2-amber-palette);
  $alcomy-staff-accent: mat.m2-define-palette($alc-light-purple);
  $alcomy-staff-warn: mat.m2-define-palette(mat.$m2-red-palette); // optional
  // Theme Definition
  $alcomy-staff-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: $alcomy-staff-primary,
        accent: $alcomy-staff-accent,
        warn: $alcomy-staff-warn
      )
    )
  );

  @include mat.all-component-colors($alcomy-staff-theme);
}
//
// 🎨 ORGANIZATION THEME V2
//
.organization-theme {
  // Theme Pallettes
  $alcomy-organization-primary: mat.m2-define-palette(
    mat.$m2-green-palette,
    A700
  );
  $alcomy-organization-accent: mat.m2-define-palette(
    mat.$m2-light-blue-palette,
    A100
  );
  $alcomy-organization-warn: mat.m2-define-palette(
    mat.$m2-red-palette
  ); // optional
  // Theme Definition
  $alcomy-organization-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: $alcomy-organization-primary,
        accent: $alcomy-organization-accent,
        warn: $alcomy-organization-warn
      )
    )
  );

  @include mat.all-component-colors($alcomy-organization-theme);
}
//
// 🎨 COMMUNICATION THEME V2
//
.communication-theme {
  // Theme Pallettes
  $alcomy-communication-primary: mat.m2-define-palette(
    mat.$m2-deep-purple-palette
  );
  $alcomy-communication-accent: mat.m2-define-palette(
    mat.$m2-green-palette,
    A200
  );
  $alcomy-communication-warn: mat.m2-define-palette(
    mat.$m2-red-palette
  ); // optional
  // Theme Definition
  $alcomy-communication-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: $alcomy-communication-primary,
        accent: $alcomy-communication-accent,
        warn: $alcomy-communication-warn
      )
    )
  );

  @include mat.all-component-colors($alcomy-communication-theme);
}
//
// 🎨 DOCUMENTS THEME
//
.documents-theme {
  // Theme Pallettes
  $alcomy-documents-primary: mat.m2-define-palette(mat.$m2-cyan-palette);
  $alcomy-documents-accent: mat.m2-define-palette(mat.$m2-yellow-palette);
  $alcomy-documents-warn: mat.m2-define-palette(
    mat.$m2-red-palette
  ); // optional
  // Theme Definition
  $alcomy-documents-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: $alcomy-documents-primary,
        accent: $alcomy-documents-accent,
        warn: $alcomy-documents-warn
      )
    )
  );
  @include mat.all-component-colors($alcomy-documents-theme);
}
//
// 🎨 TRIGGERS THEME
//
.triggers-theme {
  // Theme Pallettes
  $alcomy-triggers-primary: mat.m2-define-palette(mat.$m2-yellow-palette);
  $alcomy-triggers-accent: mat.m2-define-palette(mat.$m2-cyan-palette, A400);
  $alcomy-triggers-warn: mat.m2-define-palette(mat.$m2-red-palette); // optional
  // Theme Definition
  $alcomy-triggers-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: $alcomy-triggers-primary,
        accent: $alcomy-triggers-accent,
        warn: $alcomy-triggers-warn
      )
    )
  );

  @include mat.all-component-colors($alcomy-triggers-theme);
}
