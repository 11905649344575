@use 'mixins';

ion-modal {
  --background: var(--ion-color-surface);

  &.stacked {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  }
}

@include mixins.breakpoint-up('md') {
  .xl-modal {
    --height: 96vh;
    --width: 96vw;
    --max-width: 2000px;
  }

  .lg-modal {
    --height: 90vh;
    --width: 70vw;
    --max-width: 1400px;
  }

  .md-tall-modal {
    --height: 90vh;
    --width: 50vw;
    --max-width: 1000px;
    --min-width: 576px;
  }

  .md-modal {
    --height: 80vh;
    --width: 50vw;
    --max-width: 1000px;
    --min-width: 576px;
  }

  .sm-tall-modal {
    --height: 90vh;
    --width: 340px;
    --max-width: 1000px;
    --min-width: 576px;
  }

  .sm-modal {
    --max-width: 340px;
    --max-height: 576px;
    --border-radius: var(--alc-radius-lg);

    &.shrink {
      --min-height: 400px;
      --height: 400px;
    }
  }

  ion-modal {
    --border-radius: 24px !important;
  }

  ion-modal.fullscreen {
    --border-radius: 0 !important;
    --min-width: 100vw;
    --min-height: 100vh;
  }
}

.fullscreen {
  --height: 100vh;
  --width: 100vw;
  --border-radius: 0;
}
