:root {
  /** IONIC CSS VARIABLES **/

  /** primary **/
  --ion-color-primary: #03a9f4;
  --ion-color-primary-rgb: 3, 169, 244;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0395d7;
  --ion-color-primary-tint: #1cb2f5;

  /** secondary **/
  --ion-color-secondary: #ff4081;
  --ion-color-secondary-rgb: 255, 64, 129;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #e03872;
  --ion-color-secondary-tint: #ff538e;

  /** tertiary **/
  --ion-color-tertiary: #a78bfa;
  --ion-color-tertiary-rgb: 167, 139, 250;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #937adc;
  --ion-color-tertiary-tint: #b097fb;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** upcoming (custom) **/
  --ion-color-upcoming: #fb923c;
  --ion-color-upcoming-rgb: 251, 146, 60;
  --ion-color-upcoming-contrast: #ffffff;
  --ion-color-upcoming-contrast-rgb: 255, 255, 255;
  --ion-color-upcoming-shade: #dd8035;
  --ion-color-upcoming-tint: #fb9d50;

  /* NEUTRAL COLORS */

  //  Black
  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #000000;

  //  Very Dark
  --ion-color-very-dark: #212121;
  --ion-color-very-dark-rgb: 33, 33, 33;
  --ion-color-very-dark-contrast: #ffffff;
  --ion-color-very-dark-contrast-rgb: 255, 255, 255;
  --ion-color-very-dark-shade: #1d1d1d;
  --ion-color-very-dark-tint: #373737;

  /** dark **/
  --ion-color-dark: #424242;
  --ion-color-dark-rgb: 66, 66, 66;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #3a3a3a;
  --ion-color-dark-tint: #555555;

  /** medium-dark **/
  --ion-color-medium-dark: #757575;
  --ion-color-medium-dark-rgb: 117, 117, 117;
  --ion-color-medium-dark-contrast: #ffffff;
  --ion-color-medium-dark-contrast-rgb: 255, 255, 255;
  --ion-color-medium-dark-shade: #676767;
  --ion-color-medium-dark-tint: #838383;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  //  Medium Light
  --ion-color-medium-light: #d7d8da;
  --ion-color-medium-light-rgb: 215, 216, 218;
  --ion-color-medium-light-contrast: #000000;
  --ion-color-medium-light-contrast-rgb: 0, 0, 0;
  --ion-color-medium-light-shade: #bdbec0;
  --ion-color-medium-light-tint: #dbdcde;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  // White
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #ffffff;
  --ion-color-white-tint: #ffffff;

  --ion-color-background: var(--ion-color-light);
  --ion-color-background-rgb: var(--ion-color-light-rgb);
  --ion-color-background-contrast: var(--ion-color-light-contrast);
  --ion-color-background-contrast-rgb: var(--ion-color-light-contrast-rgb);
  --ion-color-background-shade: var(--ion-color-light-shade);
  --ion-color-background-tint: var(--ion-color-light-tint);

  --ion-color-surface: var(--ion-color-white);
  --ion-color-surface-rgb: var(--ion-color-white-rgb);
  --ion-color-surface-contrast: var(--ion-color-white-contrast);
  --ion-color-surface-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-surface-shade: var(--ion-color-white-shade);
  --ion-color-surface-tint: var(--ion-color-white-tint);

  --alc-color-background-white: var(--ion-color-white);
  /* ALCOMY BASE COLOR */

  /* This color is used as a base color for the custom alcomy component class names
  angular material theme classes that get defined below.  */

  --alcomy-color: var(--ion-color-dashboard-primary);

  /* ALCOMY MODULE COLORS */

  // Dashboard Primary
  --ion-color-dashboard-primary: #03a9f4;
  --ion-color-dashboard-primary-rgb: 3, 169, 244;
  --ion-color-dashboard-primary-contrast: #ffffff;
  --ion-color-dashboard-primary-contrast-rgb: 255, 255, 255;
  --ion-color-dashboard-primary-shade: #0395d7;
  --ion-color-dashboard-primary-tint: #1cb2f5;
  // Dashboard Accent
  --ion-color-dashboard-accent: #ff4081;
  --ion-color-dashboard-accent-rgb: 255, 64, 129;
  --ion-color-dashboard-accent-contrast: #ffffff;
  --ion-color-dashboard-accent-contrast-rgb: 255, 255, 255;
  --ion-color-dashboard-accent-shade: #e03872;
  --ion-color-dashboard-accent-tint: #ff538e;

  // Residents Primary
  --ion-color-residents-primary: #ff4081;
  --ion-color-residents-primary-rgb: 255, 64, 129;
  --ion-color-residents-primary-contrast: #ffffff;
  --ion-color-residents-primary-contrast-rgb: 255, 255, 255;
  --ion-color-residents-primary-shade: #e03872;
  --ion-color-residents-primary-tint: #ff538e;
  // Residents Accent
  --ion-color-residents-accent: #00e5ff;
  --ion-color-residents-accent-rgb: 0, 229, 255;
  --ion-color-residents-accent-contrast: #000000;
  --ion-color-residents-accent-contrast-rgb: 0, 0, 0;
  --ion-color-residents-accent-shade: #00cae0;
  --ion-color-residents-accent-tint: #1ae8ff;

  // Staff Primary
  --ion-color-staff-primary: #ffc107;
  --ion-color-staff-primary-rgb: 255, 193, 7;
  --ion-color-staff-primary-contrast: #000000;
  --ion-color-staff-primary-contrast-rgb: 0, 0, 0;
  --ion-color-staff-primary-shade: #e0aa06;
  --ion-color-staff-primary-tint: #ffc720;
  // Staff Accent
  --ion-color-staff-accent: #bc78ff;
  --ion-color-staff-accent-rgb: 188, 120, 255;
  --ion-color-staff-accent-contrast: #000000;
  --ion-color-staff-accent-contrast-rgb: 0, 0, 0;
  --ion-color-staff-accent-shade: #a56ae0;
  --ion-color-staff-accent-tint: #c386ff;

  // Communication Primary
  --ion-color-communication-primary: #673ab7;
  --ion-color-communication-primary-rgb: 103, 58, 183;
  --ion-color-communication-primary-contrast: #ffffff;
  --ion-color-communication-primary-contrast-rgb: 255, 255, 255;
  --ion-color-communication-primary-shade: #5b33a1;
  --ion-color-communication-primary-tint: #764ebe;
  // Communication Accent
  --ion-color-communication-accent: #1de9b6;
  --ion-color-communication-accent-rgb: 29, 233, 182;
  --ion-color-communication-accent-contrast: #000000;
  --ion-color-communication-accent-contrast-rgb: 0, 0, 0;
  --ion-color-communication-accent-shade: #1acda0;
  --ion-color-communication-accent-tint: #34ebbd;

  // Documents Primary
  --ion-color-documents-primary: #00bcd4;
  --ion-color-documents-primary-rgb: 0, 188, 212;
  --ion-color-documents-primary-contrast: #000000;
  --ion-color-documents-primary-contrast-rgb: 0, 0, 0;
  --ion-color-documents-primary-shade: #00a5bb;
  --ion-color-documents-primary-tint: #1ac3d8;
  // Documents Accent
  --ion-color-documents-accent: #ffeb3b;
  --ion-color-documents-accent-rgb: 255, 235, 59;
  --ion-color-documents-accent-contrast: #000000;
  --ion-color-documents-accent-contrast-rgb: 0, 0, 0;
  --ion-color-documents-accent-shade: #e0cf34;
  --ion-color-documents-accent-tint: #ffed4f;
  // Triggers Primary
  --ion-color-triggers-primary: #ffeb3b;
  --ion-color-triggers-primary-rgb: 255, 235, 59;
  --ion-color-triggers-primary-contrast: #000000;
  --ion-color-triggers-primary-contrast-rgb: 0, 0, 0;
  --ion-color-triggers-primary-shade: #e0cf34;
  --ion-color-triggers-primary-tint: #ffed4f;
  // Triggers Accent
  --ion-color-triggers-accent: #00e5ff;
  --ion-color-triggers-accent-rgb: 0, 229, 255;
  --ion-color-triggers-accent-contrast: #000000;
  --ion-color-triggers-accent-contrast-rgb: 0, 0, 0;
  --ion-color-triggers-accent-shade: #00cae0;
  --ion-color-triggers-accent-tint: #1ae8ff;

  // Organization Primary
  --ion-color-organization-primary: #00c853;
  --ion-color-organization-primary-rgb: 0, 200, 83;
  --ion-color-organization-primary-contrast: #ffffff;
  --ion-color-organization-primary-contrast-rgb: 255, 255, 255;
  --ion-color-organization-primary-shade: #00b049;
  --ion-color-organization-primary-tint: #1ace64;
  // Organization Accent
  --ion-color-organization-accent: #80d8ff;
  --ion-color-organization-accent-rgb: 128, 216, 255;
  --ion-color-organization-accent-contrast: #000000;
  --ion-color-organization-accent-contrast-rgb: 0, 0, 0;
  --ion-color-organization-accent-shade: #71bee0;
  --ion-color-organization-accent-tint: #8ddcff;

  /* ALCOMY LOGBOOK COLORS */

  // Activity Logs
  --ion-color-activity-logs: #c5000f;
  --ion-color-activity-logs-rgb: 197, 0, 15;
  --ion-color-activity-logs-contrast: #ffffff;
  --ion-color-activity-logs-contrast-rgb: 255, 255, 255;
  --ion-color-activity-logs-shade: #ad000d;
  --ion-color-activity-logs-tint: #cb1a27;

  // Behavior Logs
  --ion-color-behavior-logs: #00bcd4;
  --ion-color-behavior-logs-rgb: 0, 188, 212;
  --ion-color-behavior-logs-contrast: #000000;
  --ion-color-behavior-logs-contrast-rgb: 0, 0, 0;
  --ion-color-behavior-logs-shade: #00a5bb;
  --ion-color-behavior-logs-tint: #1ac3d8;

  // Blood Pressure Logs
  --ion-color-blood-pressure-logs: #f13933;
  --ion-color-blood-pressure-logs-rgb: 241, 57, 51;
  --ion-color-blood-pressure-logs-contrast: #ffffff;
  --ion-color-blood-pressure-logs-contrast-rgb: 255, 255, 255;
  --ion-color-blood-pressure-logs-shade: #d4322d;
  --ion-color-blood-pressure-logs-tint: #f24d47;

  // Blood Sugar Logs
  --ion-color-blood-sugar-logs: #ff5722;
  --ion-color-blood-sugar-logs-rgb: 255, 87, 34;
  --ion-color-blood-sugar-logs-contrast: #ffffff;
  --ion-color-blood-sugar-logs-contrast-rgb: 0, 0, 0;
  --ion-color-blood-sugar-logs-shade: #e04d1e;
  --ion-color-blood-sugar-logs-tint: #ff6838;

  // Body Check
  --ion-color-body-check-logs: #9c27b0;
  --ion-color-body-check-logs-rgb: 156, 39, 176;
  --ion-color-body-check-logs-contrast: #ffffff;
  --ion-color-body-check-logs-contrast-rgb: 255, 255, 255;
  --ion-color-body-check-logs-shade: #89229b;
  --ion-color-body-check-logs-tint: #a63db8;

  // Bowel Movement Logs
  --ion-color-bm-logs: #7b360f;
  --ion-color-bm-logs-rgb: 123, 54, 15;
  --ion-color-bm-logs-contrast: #ffffff;
  --ion-color-bm-logs-contrast-rgb: 255, 255, 255;
  --ion-color-bm-logs-shade: #6c300d;
  --ion-color-bm-logs-tint: #884a27;

  // Food Logs
  --ion-color-food-logs: #46a44d;
  --ion-color-food-logs-rgb: 70, 164, 77;
  --ion-color-food-logs-contrast: #ffffff;
  --ion-color-food-logs-contrast-rgb: 255, 255, 255;
  --ion-color-food-logs-shade: #3e9044;
  --ion-color-food-logs-tint: #59ad5f;

  // Mood Logs
  --ion-color-mood-logs: #ff9800;
  --ion-color-mood-logs-rgb: 255, 152, 0;
  --ion-color-mood-logs-contrast: #ffffff;
  --ion-color-mood-logs-contrast-rgb: 0, 0, 0;
  --ion-color-mood-logs-shade: #e08600;
  --ion-color-mood-logs-tint: #ffa21a;

  // Oxygen Logs
  --ion-color-oxygen-logs: #2196f3;
  --ion-color-oxygen-logs-rgb: 33, 150, 243;
  --ion-color-oxygen-logs-contrast: #ffffff;
  --ion-color-oxygen-logs-contrast-rgb: 255, 255, 255;
  --ion-color-oxygen-logs-shade: #1d84d6;
  --ion-color-oxygen-logs-tint: #37a1f4;

  // Seizure Logs
  --ion-color-seizure-logs: #8bc34a;
  --ion-color-seizure-logs-rgb: 139, 195, 74;
  --ion-color-seizure-logs-contrast: #000000;
  --ion-color-seizure-logs-contrast-rgb: 0, 0, 0;
  --ion-color-seizure-logs-shade: #7aac41;
  --ion-color-seizure-logs-tint: #97c95c;

  // Position Logs
  --ion-color-position-logs: #ff5722;
  --ion-color-position-logs-rgb: 255, 87, 34;
  --ion-color-position-logs-contrast: #000000;
  --ion-color-position-logs-contrast-rgb: 0, 0, 0;
  --ion-color-position-logs-shade: #e04d1e;
  --ion-color-position-logs-tint: #ff6838;

  // Respiration Logs
  --ion-color-respiration-logs: #7C70D7;
  --ion-color-respiration-logs-rgb: 124, 112, 215;
  --ion-color-respiration-logs-contrast: #000000;
  --ion-color-respiration-logs-contrast-rgb: 0, 0, 0;
  --ion-color-respiration-logs-shade: #6d63bd;
  --ion-color-respiration-logs-tint: #897edb;

  // Shower Logs
  --ion-color-shower-logs: #67e8f9;
  --ion-color-shower-logs-rgb: 103, 232, 249;
  --ion-color-shower-logs-contrast: #000000;
  --ion-color-shower-logs-contrast-rgb: 0, 0, 0;
  --ion-color-shower-logs-shade: #5bccdb;
  --ion-color-shower-logs-tint: #76eafa;

  // Sleep Logs
  --ion-color-sleep-logs: #3f51b5;
  --ion-color-sleep-logs-rgb: 63, 81, 181;
  --ion-color-sleep-logs-contrast: #ffffff;
  --ion-color-sleep-logs-contrast-rgb: 255, 255, 255;
  --ion-color-sleep-logs-shade: #37479f;
  --ion-color-sleep-logs-tint: #5262bc;

  // Temperature Logs
  --ion-color-temperature-logs: #ff4081;
  --ion-color-temperature-logs-rgb: 255, 64, 129;
  --ion-color-temperature-logs-contrast: #ffffff;
  --ion-color-temperature-logs-contrast-rgb: 255, 255, 255;
  --ion-color-temperature-logs-shade: #e03872;
  --ion-color-temperature-logs-tint: #ff538e;

  // Urine Logs
  --ion-color-urine-logs: #ffeb3b;
  --ion-color-urine-logs-rgb: 255, 235, 59;
  --ion-color-urine-logs-contrast: #000000;
  --ion-color-urine-logs-contrast-rgb: 0, 0, 0;
  --ion-color-urine-logs-shade: #e0cf34;
  --ion-color-urine-logs-tint: #ffed4f;

  // Vital Logs
  --ion-color-vital-logs: #DF2A4E;
  --ion-color-vital-logs-rgb: 223, 42, 78;
  --ion-color-vital-logs-contrast: #ffffff;
  --ion-color-vital-logs-contrast-rgb: 255, 255, 255;
  --ion-color-vital-logs-shade: #c42545;
  --ion-color-vital-logs-tint: #e23f60;

  // Water Logs
  --ion-color-water-logs: #10a1ee;
  --ion-color-water-logs-rgb: 16, 161, 238;
  --ion-color-water-logs-contrast: #ffffff;
  --ion-color-water-logs-contrast-rgb: 255, 255, 255;
  --ion-color-water-logs-shade: #0e8ed1;
  --ion-color-water-logs-tint: #28aaf0;

  // Weight Logs
  --ion-color-weight-logs: #6719b4;
  --ion-color-weight-logs-rgb: 103, 25, 180;
  --ion-color-weight-logs-contrast: #ffffff;
  --ion-color-weight-logs-contrast-rgb: 255, 255, 255;
  --ion-color-weight-logs-shade: #5b169e;
  --ion-color-weight-logs-tint: #7630bc;

  // Shift Notes
  --ion-color-shift-notes: #989aa2;
  --ion-color-shift-notes-rgb: 152, 154, 162;
  --ion-color-shift-notes-contrast: #ffffff;
  --ion-color-shift-notes-contrast-rgb: 255, 255, 255;
  --ion-color-shift-notes-shade: #86888f;
  --ion-color-shift-notes-tint: #a2a4ab;

  /* ALCOMY NOTIFICATION COLORS */

  // Notification Alert
  --ion-color-notification-alert: #f44336;
  --ion-color-notification-alert-rgb: 244, 67, 54;
  --ion-color-notification-alert-contrast: #ffffff;
  --ion-color-notification-alert-contrast-rgb: 255, 255, 255;
  --ion-color-notification-alert-shade: #d73b30;
  --ion-color-notification-alert-tint: #f5564a;

  // Notification Reminder
  --ion-color-notification-reminder: #03a9f4;
  --ion-color-notification-reminder-rgb: 3, 169, 244;
  --ion-color-notification-reminder-contrast: #ffffff;
  --ion-color-notification-reminder-contrast-rgb: 255, 255, 255;
  --ion-color-notification-reminder-shade: #0395d7;
  --ion-color-notification-reminder-tint: #1cb2f5;

  // Notification Message
  --ion-color-notification-message: #673ab7;
  --ion-color-notification-message-rgb: 103, 58, 183;
  --ion-color-notification-message-contrast: #ffffff;
  --ion-color-notification-message-contrast-rgb: 255, 255, 255;
  --ion-color-notification-message-shade: #5b33a1;
  --ion-color-notification-message-tint: #764ebe;

  // Dark Tabs
  --ion-color-main-tabs: #ffffff;
  --ion-color-main-tabs-rgb: 255, 255, 255;
  --ion-color-main-tabs-contrast: #424242;
  --ion-color-main-tabs-contrast-rgb: 66, 66, 66;
  --ion-color-main-tabs-shade: #1e2023;
  --ion-color-main-tabs-tint: #383a3e;

  // Indicators
  --ion-color-indicator-inactive: #9e9e9e;

  // TAGS
  // NOTE: These primary colors are from the "300" column on https://tailwindcss.com/docs/customizing-colors
  // NOTE: The "shade" and "tint" colors can be generated from the base color using https://ionicframework.com/docs/theming/color-generator

  // Medication Status Active
  --ion-color-med-status-active: #10dc60;
  --ion-color-med-status-active-rgb: 16, 220, 96;
  --ion-color-med-status-active-contrast: #ffffff;
  --ion-color-med-status-active-contrast-rgb: 255, 255, 255;
  --ion-color-med-status-active-shade: #0ec254;
  --ion-color-med-status-active-tint: #28e070;
  // Medication Status Hold
  --ion-color-med-status-hold: #fdba74;
  --ion-color-med-status-hold-rgb: 253, 186, 116;
  --ion-color-med-status-hold-contrast: #000000;
  --ion-color-med-status-hold-contrast-rgb: 0, 0, 0;
  --ion-color-med-status-hold-shade: #dfa466;
  --ion-color-med-status-hold-tint: #fdc182;
  // Medication Status Hold
  --ion-color-med-status-dc: #f04141;
  --ion-color-med-status-dc-rgb: 245, 61, 61;
  --ion-color-med-status-dc-contrast: #ffffff;
  --ion-color-med-status-dc-contrast-rgb: 255, 255, 255;
  --ion-color-med-status-dc-shade: #d33939;
  --ion-color-med-status-dc-tint: #f25454;

  // Medication Narcotic
  --ion-color-med-narcotic: #fda4af;
  --ion-color-med-narcotic-rgb: 253, 164, 175;
  --ion-color-med-narcotic-contrast: #000000;
  --ion-color-med-narcotic-contrast-rgb: 0, 0, 0;
  --ion-color-med-narcotic-shade: #df909a;
  --ion-color-med-narcotic-tint: #fdadb7;

  // Medication Crush
  --ion-color-med-crush: #c4b5fd;
  --ion-color-med-crush-rgb: 196, 181, 253;
  --ion-color-med-crush-contrast: #000000;
  --ion-color-med-crush-contrast-rgb: 0, 0, 0;
  --ion-color-med-crush-shade: #ac9fdf;
  --ion-color-med-crush-tint: #cabcfd;

  // Medication Cycle
  --ion-color-med-cycle: #fdba74;
  --ion-color-med-cycle-rgb: 253, 186, 116;
  --ion-color-med-cycle-contrast: #000000;
  --ion-color-med-cycle-contrast-rgb: 0, 0, 0;
  --ion-color-med-cycle-shade: #dfa466;
  --ion-color-med-cycle-tint: #fdc182;

  // Medication Prn
  --ion-color-med-prn: #bef264;
  --ion-color-med-prn-rgb: 190, 242, 100;
  --ion-color-med-prn-contrast: #000000;
  --ion-color-med-prn-contrast-rgb: 0, 0, 0;
  --ion-color-med-prn-shade: #a7d558;
  --ion-color-med-prn-tint: #c5f374;

  // Medication Food Requirement
  --ion-color-med-food: #5eead4;
  --ion-color-med-food-rgb: 94, 234, 212;
  --ion-color-med-food-contrast: #000000;
  --ion-color-med-food-contrast-rgb: 0, 0, 0;
  --ion-color-med-food-shade: #53cebb;
  --ion-color-med-food-tint: #6eecd8;

  // Resident DNR
  --ion-color-resident-dnr: #fda4af;
  --ion-color-resident-dnr-rgb: 253, 164, 175;
  --ion-color-resident-dnr-contrast: #000000;
  --ion-color-resident-dnr-contrast-rgb: 0, 0, 0;
  --ion-color-resident-dnr-shade: #df909a;
  --ion-color-resident-dnr-tint: #fdadb7;

  // Resident Hospice
  --ion-color-resident-hospice: #c4b5fd;
  --ion-color-resident-hospice-rgb: 196, 181, 253;
  --ion-color-resident-hospice-contrast: #000000;
  --ion-color-resident-hospice-contrast-rgb: 0, 0, 0;
  --ion-color-resident-hospice-shade: #ac9fdf;
  --ion-color-resident-hospice-tint: #cabcfd;

  // Resident Non Ambulatory
  --ion-color-resident-non-ambulatory: #fdba74;
  --ion-color-resident-non-ambulatory-rgb: 253, 186, 116;
  --ion-color-resident-non-ambulatory-contrast: #000000;
  --ion-color-resident-non-ambulatory-contrast-rgb: 0, 0, 0;
  --ion-color-resident-non-ambulatory-shade: #dfa466;
  --ion-color-resident-non-ambulatory-tint: #fdc182;

  // Document Status Pending New
  --ion-color-doc-status-new: #bae6fd;
  --ion-color-doc-status-new-rgb: 186, 230, 253;
  --ion-color-doc-status-new-contrast: #075985;
  --ion-color-doc-status-new-contrast-rgb: 7, 89, 133;
  --ion-color-doc-status-new-shade: #a4cadf;
  --ion-color-doc-status-new-tint: #c1e9fd;
  // Document Status Pending Data
  --ion-color-doc-status-data: #ddd6fe;
  --ion-color-doc-status-data-rgb: 221, 214, 254;
  --ion-color-doc-status-data-contrast: #5b21b6;
  --ion-color-doc-status-data-contrast-rgb: 91, 33, 182;
  --ion-color-doc-status-data-shade: #c2bce0;
  --ion-color-doc-status-data-tint: #e0dafe;
  // Document Status Pending Review
  --ion-color-doc-status-review: #ddd6fe;
  --ion-color-doc-status-review-rgb: 221, 214, 254;
  --ion-color-doc-status-review-contrast: #5b21b6;
  --ion-color-doc-status-review-contrast-rgb: 91, 33, 182;
  --ion-color-doc-status-review-shade: #c2bce0;
  --ion-color-doc-status-review-tint: #e0dafe;
  // Document Status Pending Signature
  --ion-color-doc-status-signature: #ddd6fe;
  --ion-color-doc-status-signature-rgb: 221, 214, 254;
  --ion-color-doc-status-signature-contrast: #5b21b6;
  --ion-color-doc-status-signature-contrast-rgb: 91, 33, 182;
  --ion-color-doc-status-signature-shade: #c2bce0;
  --ion-color-doc-status-signature-tint: #e0dafe;
  // Document Status Pending Complete
  --ion-color-doc-status-complete: #bbf7d0;
  --ion-color-doc-status-complete-rgb: 187, 247, 208;
  --ion-color-doc-status-complete-contrast: #166534;
  --ion-color-doc-status-complete-contrast-rgb: 22, 101, 52;
  --ion-color-doc-status-complete-shade: #a5d9b7;
  --ion-color-doc-status-complete-tint: #c2f8d5;

  // BUTTONS

  // POP, PASS, AND OMIT
  --ion-color-prep: var(--ion-color-warning);
  --ion-color-prep-rgb: var(--ion-color-warning-rgb);
  --ion-color-prep-contrast: var(--ion-color-warning-contrast);
  --ion-color-prep-contrast-rgb: var(--ion-color-warning-contrast-rgb);
  --ion-color-prep-shade: var(--ion-color-warning-shade);
  --ion-color-prep-tint: var(--ion-color-warning-tint);

  --ion-color-give: var(--ion-color-success);
  --ion-color-give-rgb: var(--ion-color-success-rgb);
  --ion-color-give-contrast: var(--ion-color-success-contrast);
  --ion-color-give-contrast-rgb: var(--ion-color-success-contrast-rgb);
  --ion-color-give-shade: var(--ion-color-success-shade);
  --ion-color-give-tint: var(--ion-color-success-tint);

  --ion-color-omit: var(--ion-color-danger);
  --ion-color-omit-rgb: var(--ion-color-danger-rgb);
  --ion-color-omit-contrast: var(--ion-color-danger-contrast);
  --ion-color-omit-contrast-rgb: var(--ion-color-danger-contrast-rgb);
  --ion-color-omit-shade: var(--ion-color-danger-shade);
  --ion-color-omit-tint: var(--ion-color-danger-tint);

  // Stepped Colors

  --ion-background-color: var(--ion-color-light);
  --ion-background-color-rgb: var(--ion-color-light-rgb);

  --ion-item-background: var(--ion-color-surface);
  --ion-toolbar-background: var(--ion-color-surface);
  --ion-toolbar-segment-background: var(--ion-color-surface);

  // RADIUS
  --alc-radius-sm: 8px;
  --alc-radius-md: 16px;
  --alc-radius-lg: 24px;
  --alc-card-radius-sm: var(--alc-radius-sm);
  --alc-card-radius: var(--alc-radius-md);
  --alc-card-radius-lg: var(--alc-radius-lg);
  --alc-container-border-radius: 12px;

  // Base Colors for Dark Text
  --ion-color-dark-text-1: rgba(0, 0, 0, 0.87);
  --ion-color-dark-text-2: rgba(0, 0, 0, 0.54);
  --ion-color-dark-text-3: rgba(0, 0, 0, 0.38);
  --ion-color-dark-text-4: rgba(0, 0, 0, 0.12);

  // Base Colors for Light Text
  --ion-color-light-text-1: rgba(255, 255, 255, 1);
  --ion-color-light-text-2: rgba(255, 255, 255, 0.7);
  --ion-color-light-text-3: rgba(255, 255, 255, 0.5);
  --ion-color-light-text-4: rgba(255, 255, 255, 0.12);

  --ion-color-text-1: var(--ion-color-dark-text-1);
  --ion-color-text-2: var(--ion-color-dark-text-2);
  --ion-color-text-3: var(--ion-color-dark-text-3);
  --ion-color-text-4: var(--ion-color-dark-text-4);

  // Font Sizes
  --alc-card-title: 1rem;
  --alc-text-primary-size: 1rem;
  --alc-text-secondary-size: 0.875rem;
  --alc-text-primary-color: --ion-color-dark-text-1;
  --alc-text-secondary-color: var(--ion-color-dark-text-2);

  --ion-safe-area-bottom: var(--safe-area-inset-bottom);
}

////////////////////////// MODULE COLOR CLASSES //////////////////////////

/* Classes must be formatted like so: .ion-color-{COLOR}. 

    One important thing to keep in mind is that ionic adds a class to the 
    component using the 'ion-color' prefix and the value of the 'color' attribute. 
    e.g. color="dashboard-primary" results in class="ion-color-dashboard-primary".
    That is where the following class names come into play. Ionic finds the
    class name and uses it's predefined custom properties (e.g. --ion-color-base) 
    to set the color. Under the hood ionic has predefined classes for for the 
    default colors it ships with (ion-color-primary, ion-color-secondary, etc). 
    When creating custom colors the format above regarding the class name must be
    followed; however, that format does not need to be used for the css variables
    defined under :root. Again this only applies to custom color names. Ionics
    default color names defined under :root must still exist as ionic uses those
    as the default if no color is provided to the color attribute.

    For simplicity and consistency we have named our custom colors with the
    ion-color prefix though we could change this to use a custom prefix such
    as alc-color
*/

// Dashboard Module Colors
.ion-color-dashboard-primary {
  --ion-color-base: var(--ion-color-dashboard-primary);
  --ion-color-base-rgb: var(--ion-color-dashboard-primary-rgb);
  --ion-color-contrast: var(--ion-color-dashboard-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dashboard-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-dashboard-primary-shade);
  --ion-color-tint: var(--ion-color-dashboard-primary-tint);
}

.ion-color-dashboard-accent {
  --ion-color-base: var(--ion-color-dashboard-accent);
  --ion-color-base-rgb: var(--ion-color-dashboard-accent-rgb);
  --ion-color-contrast: var(--ion-color-dashboard-accent-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dashboard-accent-contrast-rgb);
  --ion-color-shade: var(--ion-color-dashboard-accent-shade);
  --ion-color-tint: var(--ion-color-dashboard-accent-tint);
}

// Residents Module Colors
.ion-color-residents-primary {
  --ion-color-base: var(--ion-color-residents-primary);
  --ion-color-base-rgb: var(--ion-color-residents-primary-rgb);
  --ion-color-contrast: var(--ion-color-residents-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-residents-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-residents-primary-shade);
  --ion-color-tint: var(--ion-color-residents-primary-tint);
}

.ion-color-residents-accent {
  --ion-color-base: var(--ion-color-residents-accent);
  --ion-color-base-rgb: var(--ion-color-residents-accent-rgb);
  --ion-color-contrast: var(--ion-color-residents-accent-contrast);
  --ion-color-contrast-rgb: var(--ion-color-residents-accent-contrast-rgb);
  --ion-color-shade: var(--ion-color-residents-accent-shade);
  --ion-color-tint: var(--ion-color-residents-accent-tint);
}

// Staff Module Colors
.ion-color-staff-primary {
  --ion-color-base: var(--ion-color-staff-primary);
  --ion-color-base-rgb: var(--ion-color-staff-primary-rgb);
  --ion-color-contrast: var(--ion-color-staff-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-staff-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-staff-primary-shade);
  --ion-color-tint: var(--ion-color-staff-primary-tint);
}

.ion-color-staff-accent {
  --ion-color-base: var(--ion-color-staff-accent);
  --ion-color-base-rgb: var(--ion-color-staff-accent-rgb);
  --ion-color-contrast: var(--ion-color-staff-accent-contrast);
  --ion-color-contrast-rgb: var(--ion-color-staff-accent-contrast-rgb);
  --ion-color-shade: var(--ion-color-staff-accent-shade);
  --ion-color-tint: var(--ion-color-staff-accent-tint);
}

// Organization Module Colors
.ion-color-organization-primary {
  --ion-color-base: var(--ion-color-organization-primary);
  --ion-color-base-rgb: var(--ion-color-organization-primary-rgb);
  --ion-color-contrast: var(--ion-color-organization-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-organization-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-organization-primary-shade);
  --ion-color-tint: var(--ion-color-organization-primary-tint);
}

.ion-color-organization-accent {
  --ion-color-base: var(--ion-color-organization-accent);
  --ion-color-base-rgb: var(--ion-color-organization-accent-rgb);
  --ion-color-contrast: var(--ion-color-organization-accent-contrast);
  --ion-color-contrast-rgb: var(--ion-color-organization-accent-contrast-rgb);
  --ion-color-shade: var(--ion-color-organization-accent-shade);
  --ion-color-tint: var(--ion-color-organization-accent-tint);
}

// Communication Module Colors
.ion-color-communication-primary {
  --ion-color-base: var(--ion-color-communication-primary);
  --ion-color-base-rgb: var(--ion-color-communication-primary-rgb);
  --ion-color-contrast: var(--ion-color-communication-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-communication-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-communication-primary-shade);
  --ion-color-tint: var(--ion-color-communication-primary-tint);
}

.ion-color-communication-accent {
  --ion-color-base: var(--ion-color-communication-accent);
  --ion-color-base-rgb: var(--ion-color-communication-accent-rgb);
  --ion-color-contrast: var(--ion-color-communication-accent-contrast);
  --ion-color-contrast-rgb: var(--ion-color-communication-accent-contrast-rgb);
  --ion-color-shade: var(--ion-color-communication-accent-shade);
  --ion-color-tint: var(--ion-color-communication-accent-tint);
}

.ion-color-documents-primary {
  --ion-color-base: var(--ion-color-documents-primary);
  --ion-color-rbg: var(--ion-color-documents-primary-rgb);
  --ion-color-contrast: var(--ion-color-documents-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-documents-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-documents-primary-shade);
  --ion-color-tint: var(--ion-color-documents-primary-tint);
}

.ion-color-documents-accent {
  --ion-color-base: var(--ion-color-documents-accent);
  --ion-color-rbg: var(--ion-color-documents-accent-rgb);
  --ion-color-contrast: var(--ion-color-documents-accent-contrast);
  --ion-color-contrast-rgb: var(--ion-color-documents-accent-contrast-rgb);
  --ion-color-shade: var(--ion-color-documents-accent-shade);
  --ion-color-tint: var(--ion-color-documents-accent-tint);
}

.ion-color-triggers-primary {
  --ion-color-base: var(--ion-color-triggers-primary);
  --ion-color-rbg: var(--ion-color-triggers-primary-rgb);
  --ion-color-contrast: var(--ion-color-triggers-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-triggers-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-triggers-primary-shade);
  --ion-color-tint: var(--ion-color-triggers-primary-tint);
}

.ion-color-triggers-accent {
  --ion-color-base: var(--ion-color-triggers-accent);
  --ion-color-rbg: var(--ion-color-triggers-accent-rgb);
  --ion-color-contrast: var(--ion-color-triggers-accent-contrast);
  --ion-color-contrast-rgb: var(--ion-color-triggers-accent-contrast-rgb);
  --ion-color-shade: var(--ion-color-triggers-accent-shade);
  --ion-color-tint: var(--ion-color-triggers-accent-tint);
}

// LOGS COLOR CLASSES

// Behavior Logs
.ion-color-activity-logs {
  --ion-color-base: var(--ion-color-activity-logs);
  --ion-color-base-rgb: var(--ion-color-activity-logs-rgb);
  --ion-color-contrast: var(--ion-color-activity-logs-contrast);
  --ion-color-contrast-rgb: var(--ion-color-activity-logs-contrast-rgb);
  --ion-color-shade: var(--ion-color-activity-logs-shade);
  --ion-color-tint: var(--ion-color-activity-logs-tint);
}
// Behavior Logs
.ion-color-behavior-logs {
  --ion-color-base: var(--ion-color-behavior-logs);
  --ion-color-base-rgb: var(--ion-color-behavior-logs-rgb);
  --ion-color-contrast: var(--ion-color-behavior-logs-contrast);
  --ion-color-contrast-rgb: var(--ion-color-behavior-logs-contrast-rgb);
  --ion-color-shade: var(--ion-color-behavior-logs-shade);
  --ion-color-tint: var(--ion-color-behavior-logs-tint);
}

// Blood Pressure Logs
.ion-color-blood-pressure-logs {
  --ion-color-base: var(--ion-color-blood-pressure-logs);
  --ion-color-base-rgb: var(--ion-color-blood-pressure-logs-rgb);
  --ion-color-contrast: var(--ion-color-blood-pressure-logs-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blood-pressure-logs-contrast-rgb);
  --ion-color-shade: var(--ion-color-blood-pressure-logs-shade);
  --ion-color-tint: var(--ion-color-blood-pressure-logs-tint);
}

// Blood Sugar Logs
.ion-color-blood-sugar-logs {
  --ion-color-base: var(--ion-color-blood-sugar-logs);
  --ion-color-base-rgb: var(--ion-color-blood-sugar-logs-rgb);
  --ion-color-contrast: var(--ion-color-blood-sugar-logs-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blood-sugar-logs-contrast-rgb);
  --ion-color-shade: var(--ion-color-blood-sugar-logs-shade);
  --ion-color-tint: var(--ion-color-blood-sugar-logs-tint);
}

// Body Check Logs
.ion-color-body-check-logs {
  --ion-color-base: var(--ion-color-body-check-logs);
  --ion-color-base-rgb: var(--ion-color-body-check-logs-rgb);
  --ion-color-contrast: var(--ion-color-body-check-logs-contrast);
  --ion-color-contrast-rgb: var(--ion-color-body-check-logs-contrast-rgb);
  --ion-color-shade: var(--ion-color-body-check-logs-shade);
  --ion-color-tint: var(--ion-color-body-check-logs-tint);
}

// Bowel Movement Logs
.ion-color-bm-logs {
  --ion-color-base: var(--ion-color-bm-logs);
  --ion-color-base-rgb: var(--ion-color-bm-logs-rgb);
  --ion-color-contrast: var(--ion-color-bm-logs-contrast);
  --ion-color-contrast-rgb: var(--ion-color-bm-logs-contrast-rgb);
  --ion-color-shade: var(--ion-color-bm-logs-shade);
  --ion-color-tint: var(--ion-color-bm-logs-tint);
}

// Food Logs
.ion-color-food-logs {
  --ion-color-base: var(--ion-color-food-logs);
  --ion-color-base-rgb: var(--ion-color-food-logs-rgb);
  --ion-color-contrast: var(--ion-color-food-logs-contrast);
  --ion-color-contrast-rgb: var(--ion-color-food-logs-contrast-rgb);
  --ion-color-shade: var(--ion-color-food-logs-shade);
  --ion-color-tint: var(--ion-color-food-logs-tint);
}

// Mood Logs
.ion-color-mood-logs {
  --ion-color-base: var(--ion-color-mood-logs);
  --ion-color-base-rgb: var(--ion-color-mood-logs-rgb);
  --ion-color-contrast: var(--ion-color-mood-logs-contrast);
  --ion-color-contrast-rgb: var(--ion-color-mood-logs-contrast-rgb);
  --ion-color-shade: var(--ion-color-mood-logs-shade);
  --ion-color-tint: var(--ion-color-mood-logs-tint);
}

// Oxygen Logs
.ion-color-oxygen-logs {
  --ion-color-base: var(--ion-color-oxygen-logs);
  --ion-color-base-rgb: var(--ion-color-oxygen-logs-rgb);
  --ion-color-contrast: var(--ion-color-oxygen-logs-contrast);
  --ion-color-contrast-rgb: var(--ion-color-oxygen-logs-contrast-rgb);
  --ion-color-shade: var(--ion-color-oxygen-logs-shade);
  --ion-color-tint: var(--ion-color-oxygen-logs-tint);
}

// Position Logs
.ion-color-position-logs {
  --ion-color-base: var(--ion-color-position-logs);
  --ion-color-base-rgb: var(--ion-color-position-logs-rgb);
  --ion-color-contrast: var(--ion-color-position-logs-contrast);
  --ion-color-contrast-rgb: var(--ion-color-position-logs-contrast-rgb);
  --ion-color-shade: var(--ion-color-position-logs-shade);
  --ion-color-tint: var(--ion-color-position-logs-tint);
}


// Respiration Logs
.ion-color-respiration-logs {
  --ion-color-base: var(--ion-color-respiration-logs);
  --ion-color-base-rgb: var(--ion-color-respiration-logs-rgb);
  --ion-color-contrast: var(--ion-color-respiration-logs-contrast);
  --ion-color-contrast-rgb: var(--ion-color-respiration-logs-contrast-rgb);
  --ion-color-shade: var(--ion-color-respiration-logs-shade);
  --ion-color-tint: var(--ion-color-respiration-logs-tint);
}

// Seizure Logs
.ion-color-seizure-logs {
  --ion-color-base: var(--ion-color-seizure-logs);
  --ion-color-base-rgb: var(--ion-color-seizure-logs-rgb);
  --ion-color-contrast: var(--ion-color-seizure-logs-contrast);
  --ion-color-contrast-rgb: var(--ion-color-seizure-logs-contrast-rgb);
  --ion-color-shade: var(--ion-color-seizure-logs-shade);
  --ion-color-tint: var(--ion-color-seizure-logs-tint);
}

// Shower Logs
.ion-color-shower-logs {
  --ion-color-base: var(--ion-color-shower-logs);
  --ion-color-base-rgb: var(--ion-color-shower-logs-rgb);
  --ion-color-contrast: var(--ion-color-shower-logs-contrast);
  --ion-color-contrast-rgb: var(--ion-color-shower-logs-contrast-rgb);
  --ion-color-shade: var(--ion-color-shower-logs-shade);
  --ion-color-tint: var(--ion-color-shower-logs-tint);
}

// Sleep Logs
.ion-color-sleep-logs {
  --ion-color-base: var(--ion-color-sleep-logs);
  --ion-color-base-rgb: var(--ion-color-sleep-logs-rgb);
  --ion-color-contrast: var(--ion-color-sleep-logs-contrast);
  --ion-color-contrast-rgb: var(--ion-color-sleep-logs-contrast-rgb);
  --ion-color-shade: var(--ion-color-sleep-logs-shade);
  --ion-color-tint: var(--ion-color-sleep-logs-tint);
}

// Temperature Logs
.ion-color-temperature-logs {
  --ion-color-base: var(--ion-color-temperature-logs);
  --ion-color-base-rgb: var(--ion-color-temperature-logs-rgb);
  --ion-color-contrast: var(--ion-color-temperature-logs-contrast);
  --ion-color-contrast-rgb: var(--ion-color-temperature-logs-contrast-rgb);
  --ion-color-shade: var(--ion-color-temperature-logs-shade);
  --ion-color-tint: var(--ion-color-temperature-logs-tint);
}

// Urine Logs
.ion-color-urine-logs {
  --ion-color-base: var(--ion-color-urine-logs);
  --ion-color-base-rgb: var(--ion-color-urine-logs-rgb);
  --ion-color-contrast: var(--ion-color-urine-logs-contrast);
  --ion-color-contrast-rgb: var(--ion-color-urine-logs-contrast-rgb);
  --ion-color-shade: var(--ion-color-urine-logs-shade);
  --ion-color-tint: var(--ion-color-urine-logs-tint);
}


// Vital Logs
.ion-color-vital-logs {
  --ion-color-base: var(--ion-color-vital-logs);
  --ion-color-base-rgb: var(--ion-color-vital-logs-rgb);
  --ion-color-contrast: var(--ion-color-vital-logs-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vital-logs-contrast-rgb);
  --ion-color-shade: var(--ion-color-vital-logs-shade);
  --ion-color-tint: var(--ion-color-vital-logs-tint);
}


// Water Logs
.ion-color-water-logs {
  --ion-color-base: var(--ion-color-water-logs);
  --ion-color-base-rgb: var(--ion-color-water-logs-rgb);
  --ion-color-contrast: var(--ion-color-water-logs-contrast);
  --ion-color-contrast-rgb: var(--ion-color-water-logs-contrast-rgb);
  --ion-color-shade: var(--ion-color-water-logs-shade);
  --ion-color-tint: var(--ion-color-water-logs-tint);
}

// Weight Logs
.ion-color-weight-logs {
  --ion-color-base: var(--ion-color-weight-logs);
  --ion-color-base-rgb: var(--ion-color-weight-logs-rgb);
  --ion-color-contrast: var(--ion-color-weight-logs-contrast);
  --ion-color-contrast-rgb: var(--ion-color-weight-logs-contrast-rgb);
  --ion-color-shade: var(--ion-color-weight-logs-shade);
  --ion-color-tint: var(--ion-color-weight-logs-tint);
}

// Shift Notes
.ion-color-shift-notes {
  --ion-color-base: var(--ion-color-shift-notes);
  --ion-color-base-rgb: var(--ion-color-shift-notes-rgb);
  --ion-color-contrast: var(--ion-color-shift-notes-contrast);
  --ion-color-contrast-rgb: var(--ion-color-shift-notes-contrast-rgb);
  --ion-color-shade: var(--ion-color-shift-notes-shade);
  --ion-color-tint: var(--ion-color-shift-notes-tint);
}

// Misc Colors
.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-very-dark {
  --ion-color-base: var(--ion-color-very-dark);
  --ion-color-base-rgb: var(--ion-color-very-dark-rgb);
  --ion-color-contrast: var(--ion-color-very-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-very-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-very-dark-shade);
  --ion-color-tint: var(--ion-color-very-dark-tint);
}

.ion-color-dark {
  --ion-color-base: var(--ion-color-dark);
  --ion-color-base-rgb: var(--ion-color-dark-rgb);
  --ion-color-contrast: var(--ion-color-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-shade);
  --ion-color-tint: var(--ion-color-dark-tint);
}

.ion-color-medium-dark {
  --ion-color-base: var(--ion-color-medium-dark);
  --ion-color-base-rgb: var(--ion-color-medium-dark-rgb);
  --ion-color-contrast: var(--ion-color-medium-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-medium-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-medium-dark-shade);
  --ion-color-tint: var(--ion-color-medium-dark-tint);
}

.ion-color-medium {
  --ion-color-base: var(--ion-color-medium);
  --ion-color-base-rgb: var(--ion-color-medium-rgb);
  --ion-color-contrast: var(--ion-color-medium-contrast);
  --ion-color-contrast-rgb: var(--ion-color-medium-contrast-rgb);
  --ion-color-shade: var(--ion-color-medium-shade);
  --ion-color-tint: var(--ion-color-medium-tint);
}

.ion-color-medium-light {
  --ion-color-base: var(--ion-color-medium-light);
  --ion-color-base-rgb: var(--ion-color-medium-light-rgb);
  --ion-color-contrast: var(--ion-color-medium-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-medium-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-medium-light-shade);
  --ion-color-tint: var(--ion-color-medium-light-tint);
}

.ion-color-light {
  --ion-color-base: var(--ion-color-light);
  --ion-color-base-rgb: var(--ion-color-light-rgb);
  --ion-color-contrast: var(--ion-color-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-shade);
  --ion-color-tint: var(--ion-color-light-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-background {
  --ion-color-base: var(--ion-color-background);
  --ion-color-base-rgb: var(--ion-color-background-rgb);
  --ion-color-contrast: var(--ion-color-background-contrast);
  --ion-color-contrast-rgb: var(--ion-color-background-contrast-rgb);
  --ion-color-shade: var(--ion-color-background-shade);
  --ion-color-tint: var(--ion-color-background-tint);
}

.ion-color-surface {
  --ion-color-base: var(--ion-color-surface);
  --ion-color-base-rgb: var(--ion-color-surface-rgb);
  --ion-color-contrast: var(--ion-color-surface-contrast);
  --ion-color-contrast-rgb: var(--ion-color-surface-contrast-rgb);
  --ion-color-shade: var(--ion-color-surface-shade);
  --ion-color-tint: var(--ion-color-surface-tint);
}

.ion-color-main-tabs {
  --ion-color-base: var(--ion-color-main-tabs);
  --ion-color-base-rgb: var(--ion-color-main-tabs-rgb);
  --ion-color-contrast: var(--ion-color-main-tabs-contrast);
  --ion-color-contrast-rgb: var(--ion-color-main-tabs-contrast-rgb);
  --ion-color-shade: var(--ion-color-main-tabs-shade);
  --ion-color-tint: var(--ion-color-main-tabs-tint);
}

// MEDICATION ADMINISTRATION ACTION BUTTONS

.ion-color-prep {
  --ion-color-base: var(--ion-color-prep);
  --ion-color-base-rgb: var(--ion-color-prep-rgb);
  --ion-color-contrast: var(--ion-color-prep-contrast);
  --ion-color-contrast-rgb: var(--ion-color-prep-contrast-rgb);
  --ion-color-shade: var(--ion-color-prep-shade);
  --ion-color-tint: var(--ion-color-prep-tint);
}

.ion-color-give {
  --ion-color-base: var(--ion-color-give);
  --ion-color-base-rgb: var(--ion-color-give-rgb);
  --ion-color-contrast: var(--ion-color-give-contrast);
  --ion-color-contrast-rgb: var(--ion-color-give-contrast-rgb);
  --ion-color-shade: var(--ion-color-give-shade);
  --ion-color-tint: var(--ion-color-give-tint);
}

.ion-color-omit {
  --ion-color-base: var(--ion-color-omit);
  --ion-color-base-rgb: var(--ion-color-omit-rgb);
  --ion-color-contrast: var(--ion-color-omit-contrast);
  --ion-color-contrast-rgb: var(--ion-color-omit-contrast-rgb);
  --ion-color-shade: var(--ion-color-omit-shade);
  --ion-color-tint: var(--ion-color-omit-tint);
}

// NOTIFICATION COLOR CLASSES //////////////////////////////////////////////

// Notification Alert
.ion-color-notification-alert {
  --ion-color-base: var(--ion-color-notification-alert);
  --ion-color-base-rgb: var(--ion-color-notification-alert-rgb);
  --ion-color-contrast: var(--ion-color-notification-alert-contrast);
  --ion-color-contrast-rgb: var(--ion-color-notification-alert-contrast-rgb);
  --ion-color-shade: var(--ion-color-notification-alert-shade);
  --ion-color-tint: var(--ion-color-notification-alert-tint);
}

// Notification Reminder
.ion-color-notification-reminder {
  --ion-color-base: var(--ion-color-notification-reminder);
  --ion-color-base-rgb: var(--ion-color-notification-reminder-rgb);
  --ion-color-contrast: var(--ion-color-notification-reminder-contrast);
  --ion-color-contrast-rgb: var(--ion-color-notification-reminder-contrast-rgb);
  --ion-color-shade: var(--ion-color-notification-reminder-shade);
  --ion-color-tint: var(--ion-color-notification-reminder-tint);
}

// Notification Message
.ion-color-notification-message {
  --ion-color-base: var(--ion-color-notification-message);
  --ion-color-base-rgb: var(--ion-color-notification-message-rgb);
  --ion-color-contrast: var(--ion-color-notification-message-contrast);
  --ion-color-contrast-rgb: var(--ion-color-notification-message-contrast-rgb);
  --ion-color-shade: var(--ion-color-notification-message-shade);
  --ion-color-tint: var(--ion-color-notification-message-tint);
}

// TAGS ///////////////////////////////////////////////////////////////

// Medication Status Hold
.ion-color-med-status-active {
  --ion-color-base: var(--ion-color-med-status-active);
  --ion-color-base-rgb: var(--ion-color-med-status-active-rgb);
  --ion-color-contrast: var(--ion-color-med-status-active-contrast);
  --ion-color-contrast-rgb: var(--ion-color-med-status-active-contrast-rgb);
  --ion-color-shade: var(--ion-color-med-status-active-shade);
  --ion-color-tint: var(--ion-color-med-status-active-tint);
}

// Medication Status Hold
.ion-color-med-status-hold {
  --ion-color-base: var(--ion-color-med-status-hold);
  --ion-color-base-rgb: var(--ion-color-med-status-hold-rgb);
  --ion-color-contrast: var(--ion-color-med-status-hold-contrast);
  --ion-color-contrast-rgb: var(--ion-color-med-status-hold-contrast-rgb);
  --ion-color-shade: var(--ion-color-med-status-hold-shade);
  --ion-color-tint: var(--ion-color-med-status-hold-tint);
}

// Medication Status DC
.ion-color-med-status-dc {
  --ion-color-base: var(--ion-color-med-status-dc);
  --ion-color-base-rgb: var(--ion-color-med-status-dc-rgb);
  --ion-color-contrast: var(--ion-color-med-status-dc-contrast);
  --ion-color-contrast-rgb: var(--ion-color-med-status-dc-contrast-rgb);
  --ion-color-shade: var(--ion-color-med-status-dc-shade);
  --ion-color-tint: var(--ion-color-med-status-dc-tint);
}

// Medication Crush
.ion-color-med-narcotic {
  --ion-color-base: var(--ion-color-med-narcotic);
  --ion-color-base-rgb: var(--ion-color-med-narcotic-rgb);
  --ion-color-contrast: var(--ion-color-med-narcotic-contrast);
  --ion-color-contrast-rgb: var(--ion-color-med-narcotic-contrast-rgb);
  --ion-color-shade: var(--ion-color-med-narcotic-shade);
  --ion-color-tint: var(--ion-color-med-narcotic-tint);
}

// Medication Crush
.ion-color-med-crush {
  --ion-color-base: var(--ion-color-med-crush);
  --ion-color-base-rgb: var(--ion-color-med-crush-rgb);
  --ion-color-contrast: var(--ion-color-med-crush-contrast);
  --ion-color-contrast-rgb: var(--ion-color-med-crush-contrast-rgb);
  --ion-color-shade: var(--ion-color-med-crush-shade);
  --ion-color-tint: var(--ion-color-med-crush-tint);
}

// Medication Cycle
.ion-color-med-cycle {
  --ion-color-base: var(--ion-color-med-cycle);
  --ion-color-base-rgb: var(--ion-color-med-cycle-rgb);
  --ion-color-contrast: var(--ion-color-med-cycle-contrast);
  --ion-color-contrast-rgb: var(--ion-color-med-cycle-contrast-rgb);
  --ion-color-shade: var(--ion-color-med-cycle-shade);
  --ion-color-tint: var(--ion-color-med-cycle-tint);
}

// Medication Prn
.ion-color-med-prn {
  --ion-color-base: var(--ion-color-med-prn);
  --ion-color-base-rgb: var(--ion-color-med-prn-rgb);
  --ion-color-contrast: var(--ion-color-med-prn-contrast);
  --ion-color-contrast-rgb: var(--ion-color-med-prn-contrast-rgb);
  --ion-color-shade: var(--ion-color-med-prn-shade);
  --ion-color-tint: var(--ion-color-med-prn-tint);
}

// Medication Food Requirement
.ion-color-med-food {
  --ion-color-base: var(--ion-color-med-food);
  --ion-color-base-rgb: var(--ion-color-med-food-rgb);
  --ion-color-contrast: var(--ion-color-med-food-contrast);
  --ion-color-contrast-rgb: var(--ion-color-med-food-contrast-rgb);
  --ion-color-shade: var(--ion-color-med-food-shade);
  --ion-color-tint: var(--ion-color-med-food-tint);
}

// Resident DNR
.ion-color-resident-dnr {
  --ion-color-base: var(--ion-color-resident-dnr);
  --ion-color-base-rgb: var(--ion-color-resident-dnr-rgb);
  --ion-color-contrast: var(--ion-color-resident-dnr-contrast);
  --ion-color-contrast-rgb: var(--ion-color-resident-dnr-contrast-rgb);
  --ion-color-shade: var(--ion-color-resident-dnr-shade);
  --ion-color-tint: var(--ion-color-resident-dnr-tint);
}

// Resident Non Ambulatory
.ion-color-resident-non-ambulatory {
  --ion-color-base: var(--ion-color-resident-non-ambulatory);
  --ion-color-base-rgb: var(--ion-color-resident-non-ambulatory-rgb);
  --ion-color-contrast: var(--ion-color-resident-non-ambulatory-contrast);
  --ion-color-contrast-rgb: var(
    --ion-color-resident-non-ambulatory-contrast-rgb
  );
  --ion-color-shade: var(--ion-color-resident-non-ambulatory-shade);
  --ion-color-tint: var(--ion-color-resident-non-ambulatory-tint);
}

// Resident DNR
.ion-color-resident-hospice {
  --ion-color-base: var(--ion-color-resident-hospice);
  --ion-color-base-rgb: var(--ion-color-resident-hospice-rgb);
  --ion-color-contrast: var(--ion-color-resident-hospice-contrast);
  --ion-color-contrast-rgb: var(--ion-color-resident-hospice-contrast-rgb);
  --ion-color-shade: var(--ion-color-resident-hospice-shade);
  --ion-color-tint: var(--ion-color-resident-hospice-tint);
}

// Document Status New
.ion-color-doc-status-new {
  --ion-color-base: var(--ion-color-doc-status-new);
  --ion-color-base-rgb: var(--ion-color-doc-status-new-rgb);
  --ion-color-contrast: var(--ion-color-doc-status-new-contrast);
  --ion-color-contrast-rgb: var(--ion-color-doc-status-new-contrast-rgb);
  --ion-color-shade: var(--ion-color-doc-status-new-shade);
  --ion-color-tint: var(--ion-color-doc-status-new-tint);
}

// Document Status Pending Data
.ion-color-doc-status-data {
  --ion-color-base: var(--ion-color-doc-status-data);
  --ion-color-base-rgb: var(--ion-color-doc-status-data-rgb);
  --ion-color-contrast: var(--ion-color-doc-status-data-contrast);
  --ion-color-contrast-rgb: var(--ion-color-doc-status-data-contrast-rgb);
  --ion-color-shade: var(--ion-color-doc-status-data-shade);
  --ion-color-tint: var(--ion-color-doc-status-data-tint);
}

// Document Status Pending Review
.ion-color-doc-status-review {
  --ion-color-base: var(--ion-color-doc-status-review);
  --ion-color-base-rgb: var(--ion-color-doc-status-review-rgb);
  --ion-color-contrast: var(--ion-color-doc-status-review-contrast);
  --ion-color-contrast-rgb: var(--ion-color-doc-status-review-contrast-rgb);
  --ion-color-shade: var(--ion-color-doc-status-review-shade);
  --ion-color-tint: var(--ion-color-doc-status-review-tint);
}

// Document Status Signature
.ion-color-doc-status-signature {
  --ion-color-base: var(--ion-color-doc-status-signature);
  --ion-color-base-rgb: var(--ion-color-doc-status-signature-rgb);
  --ion-color-contrast: var(--ion-color-doc-status-signature-contrast);
  --ion-color-contrast-rgb: var(--ion-color-doc-status-signature-contrast-rgb);
  --ion-color-shade: var(--ion-color-doc-status-signature-shade);
  --ion-color-tint: var(--ion-color-doc-status-signature-tint);
}

// Document Status Complete
.ion-color-doc-status-complete {
  --ion-color-base: var(--ion-color-doc-status-complete);
  --ion-color-base-rgb: var(--ion-color-doc-status-complete-rgb);
  --ion-color-contrast: var(--ion-color-doc-status-complete-contrast);
  --ion-color-contrast-rgb: var(--ion-color-doc-status-complete-contrast-rgb);
  --ion-color-shade: var(--ion-color-doc-status-complete-shade);
  --ion-color-tint: var(--ion-color-doc-status-complete-tint);
}

// MORE BASE COLORS
.ion-color-upcoming {
  --ion-color-base: var(--ion-color-upcoming);
  --ion-color-base-rgb: var(--ion-color-upcoming-rgb);
  --ion-color-contrast: var(--ion-color-upcoming-contrast);
  --ion-color-contrast-rgb: var(--ion-color-upcoming-contrast-rgb);
  --ion-color-shade: var(--ion-color-upcoming-shade);
  --ion-color-tint: var(--ion-color-upcoming-tint);
}

// CUSTOM COMPONENT COLOR CLASSES /////////////////////////////////////

/* The following classes are to be applied to custom alcomy components

    This is akin to the way ionic does its theming. The concept is not
    completely flushed out and the proper mechanics need to be implemented
    in the components themselves, but the idea is that alcomy generate a
    class and applies it to the component using a combination of the prefix
    and color name. Similar to ionic.

*/

// Dashboard Module Colors
.alcomy-dashboard-primary {
  --alcomy-color: var(--ion-color-dashboard-primary);
  --alcomy-color-contrast: var(--ion-color-dashboard-primary-contrast);
}

.alcomy-dashboard-accent {
  --alcomy-color: var(--ion-color-dashboard-accent);
  --alcomy-color-contrast: var(--ion-color-dashboard-accent-contrast);
}

// Residents Module Colors
.alcomy-residents-primary {
  --alcomy-color: var(--ion-color-residents-primary);
  --alcomy-color-contrast: var(--ion-color-residents-primary-contrast);
}

.alcomy-residents-accent {
  --alcomy-color: var(--ion-color-residents-accent);
  --alcomy-color-contrast: var(--ion-color-residents-accent-contrast);
}

// Staff Module Colors
.alcomy-staff-primary {
  --alcomy-color: var(--ion-color-staff-primary);
  --alcomy-color-contrast: var(--ion-color-staff-primary-contrast);
}

.alcomy-staff-accent {
  --alcomy-color: var(--ion-color-staff-accent);
  --alcomy-color-contrast: var(--ion-color-staff-accent-contrast);
}

// Documents Module Colors
.alcomy-documents-primary {
  --alcomy-color: var(--ion-color-documents-primary);
  --alcomy-color-contrast: var(--ion-color-documents-primary-contrast);
}

.alcomy-documents-accent {
  --alcomy-color: var(--ion-color-documents-accent);
  --alcomy-color-contrast: var(--ion-color-documents-accent-contrast);
}

// Triggers Module Colors
.alcomy-triggers-primary {
  --alcomy-color: var(--ion-color-triggers-primary);
  --alcomy-color-contrast: var(--ion-color-triggers-primary-contrast);
}

.alcomy-triggers-accent {
  --alcomy-color: var(--ion-color-triggers-accent);
  --alcomy-color-contrast: var(--ion-color-triggers-accent-contrast);
}

// Communication Module Colors
.alcomy-communication-primary {
  --alcomy-color: var(--ion-color-communication-primary);
  --alcomy-color-contrast: var(--ion-color-communication-primary-contrast);
}

.alcomy-communication-accent {
  --alcomy-color: var(--ion-color-communication-accent);
  --alcomy-color-contrast: var(--ion-color-communication-accent-contrast);
}

// Organization Module Colors
.alcomy-organization-primary {
  --alcomy-color: var(--ion-color-organization-primary);
  --alcomy-color-contrast: var(--ion-color-organization-primary-contrast);
}

.alcomy-organization-accent {
  --alcomy-color: var(--ion-color-organization-accent);
  --alcomy-color-contrast: var(--ion-color-organization-accent-contrast);
}

.alcomy-success {
  --alcomy-color: var(--ion-color-success);
  --alcomy-color-contrast: var(--ion-color-success-contrast);
}

.alcomy-warning {
  --alcomy-color: var(--ion-color-warning);
  --alcomy-color-contrast: var(--ion-color-warning-contrast);
}

.alcomy-danger {
  --alcomy-color: var(--ion-color-danger);
  --alcomy-color-contrast: var(--ion-color-danger-contrast);
}

// Neutral Colors
.alcomy-dark {
  --alcomy-color: var(--ion-color-dark);
  --alcomy-color-contrast: var(--ion-color-dark-contrast);
}

.alcomy-medium-dark {
  --alcomy-color: var(--ion-color-medium-dark);
  --alcomy-color-contrast: var(--ion-color-medium-dark-contrast);
}

.alcomy-medium {
  --alcomy-color: var(--ion-color-medium);
  --alcomy-color-contrast: var(--ion-color-medium-contrast);
}

.alcomy-medium-light {
  --alcomy-color: var(--ion-color-medium-light);
  --alcomy-color-contrast: var(--ion-color-medium-light-contrast);
}

.alcomy-light {
  --alcomy-color: var(--ion-color-light);
  --alcomy-color-contrast: var(--ion-color-light-contrast);
}
