// HACK: .form-popover, .phone-form-popover, and .alert-popover must be defined individually
// or the popover will not be sized correctly. If you try to simplify the syntax by writing
// .form-popover, .phone-form-popover, .alert-popover { --width: 320px; } the popover will
// not be sized correctly.
.form-popover {
  --width: 320px;
}

.phone-form-popover {
  --height: 320px;
}

.alert-popover {
  --width: 320px;
}

ion-popover::part(content) {
  border-radius: 24px !important;
}

ion-popover {
  --background: var(--ion-color-surface);

  ion-content {
    --background: var(--ion-color-surface);
  }

  ion-header,
  ion-footer {
    ion-toolbar:first-of-type {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
