// Angular Material's drag and drop inserts the item preview in the body
// element. This effectively doesn't allow the styles in the
// component to be applied to the preview. Therefore we have to
// add them to the global styles. We should be able to use
// cdkDragPreviewContainer and set it to parent or another element
// but for some reason this doesn't seem to work. Thus the below
// styles. See angular material docs for more info on this:
// https://material.angular.io/cdk/drag-drop/overview#drag-preview-insertion-point

.drag-item.inventory-item {
  display: flex;
  flex-direction: row;
  padding-inline: 16px;
  height: 64px;
  .drag-item-start {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: content;
    margin-inline-end: 24px;
  }
  .drag-item-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      font-size: 16px;
      margin: 0;
    }

    .row-1,
    .row-2 {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .row-1 {
      height: 32px;
    }

    .row-2 {
      height: 20px;
      color: var(--ion-color-medium);
      .indicator-label {
        font-size: 14px;
      }
    }
  }
  .drag-item-end {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: content;
    margin-inline-start: 24px;
  }
}
